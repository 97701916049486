import React, { useState, useEffect } from "react";

import * as Yup from "yup";
import { Formik, FieldArray, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Box, FormControlLabel, Switch } from "@material-ui/core";

import { Can, check } from "../Can";

import { useAuthUser } from "../../context/Auth";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },

  extraAttr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const ContactSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  number: Yup.string().min(8, "Too Short!").max(50, "Too Long!"),
  email: Yup.string().email("Invalid email"),
  extraInfo: Yup.array(
    Yup.object().shape({
      value: Yup.string().min(3).max(1000).required(),
    }),
  ),
});

const ContactModal = ({ open, onClose, contactId, initialValues, onSave }) => {
  const classes = useStyles();
  const initialState = {
    name: "",
    number: "",
    email: "",
    isEdited: true,
    isGroup: false,
    extraInfo: [],
  };

  const [contact, setContact] = useState(initialState);
  const [isLoading, setLoading] = useState(false);
  const { user } = useAuthUser();

  useEffect(() => {
    const fetchContact = async () => {
      if (!contactId) return;

      try {
        setLoading(true);
        const { data } = await api.get(`/contacts/${contactId}`);
        setContact(data);
      } catch (err) {
        toastError(err);
      }
    };

    fetchContact().finally(() => setLoading(false));
  }, [contactId, open, initialValues]);

  const handleClose = () => {
    onClose();
    setContact(initialState);
  };

  const handleSaveContact = async (values) => {
    try {
      if (contactId) {
        await api.put(`/contacts/${contactId}`, values);
        handleClose();
      } else {
        const { data } = await api.post("/contacts", values);
        if (onSave) {
          onSave(data);
        }
      }
      toast.success(i18n.t("contactModal.success"));
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  if (!open) {
    return <></>;
  }

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
        <DialogTitle id="form-dialog-title">
          {contactId
            ? `${i18n.t("contactModal.title.edit")}`
            : `${i18n.t("contactModal.title.add")}`}
        </DialogTitle>
        {!isLoading ? (
          <Formik
            initialValues={contact}
            enableReinitialize={true}
            validationSchema={ContactSchema}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                handleSaveContact(values);
                actions.setSubmitting(false);
              }, 400);
            }}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form>
                <DialogContent dividers>
                  <Typography variant="subtitle1" gutterBottom>
                    {i18n.t("contactModal.form.mainInfo")}
                  </Typography>
                  <Field
                    as={TextField}
                    label={i18n.t("contactModal.form.name")}
                    name="name"
                    autoFocus
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                  />
                  <Field
                    as={TextField}
                    label={i18n.t("contactModal.form.number")}
                    name="number"
                    error={touched.number && Boolean(errors.number)}
                    helperText={touched.number && errors.number}
                    placeholder="5513912344321"
                    disabled={values.isGroup}
                    variant="outlined"
                    margin="dense"
                  />
                  <div>
                    <Field
                      as={TextField}
                      label={i18n.t("contactModal.form.email")}
                      name="email"
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      placeholder="Email address"
                      fullWidth
                      margin="dense"
                      variant="outlined"
                    />
                  </div>

                  <Field
                    label={i18n.t("contactModal.form.isEdited")}
                    name="isEdited"
                    variant="outlined"
                    margin="dense"
                  >
                    {({ field }) => (
                      <FormControlLabel
                        control={
                          <Switch defaultChecked={values.isEdited} {...field} />
                        }
                        label={i18n.t("contactModal.form.isEdited")}
                      />
                    )}
                  </Field>
                  <Typography
                    style={{ marginBottom: 8, marginTop: 12 }}
                    variant="subtitle1"
                  >
                    {i18n.t("contactModal.form.extraInfo")}
                  </Typography>

                  <FieldArray name="extraInfo">
                    {({ push, remove }) => (
                      <>
                        {values.extraInfo &&
                          values.extraInfo.length > 0 &&
                          values.extraInfo.map((info, index) => (
                            <div
                              className={classes.extraAttr}
                              key={`${index}-info`}
                            >
                              <Field
                                as={TextField}
                                multiline
                                label={i18n.t("contactModal.form.extraValue")}
                                name={`extraInfo[${index}].value`}
                                disabled={
                                  !check(user.profile, "contact-modal:edit")
                                }
                                variant="outlined"
                                margin="dense"
                                error={
                                  touched.extraInfo &&
                                  touched.extraInfo[index] &&
                                  errors.extraInfo &&
                                  errors.extraInfo[index]
                                }
                                helperText={
                                  touched.extraInfo &&
                                  errors.extraInfo &&
                                  errors.extraInfo[index] &&
                                  errors.extraInfo[index].value
                                }
                                className={classes.textField}
                              />
                              <Can
                                perform={"contact-modal:edit"}
                                role={user.profile}
                                yes={() => (
                                  <IconButton
                                    size="small"
                                    onClick={() => remove(index)}
                                  >
                                    <DeleteOutlineIcon />
                                  </IconButton>
                                )}
                              />
                            </div>
                          ))}
                        <div className={classes.extraAttr}>
                          <Button
                            style={{ flex: 1, marginTop: 8 }}
                            variant="outlined"
                            color="primary"
                            onClick={() => push({ name: "", value: "" })}
                          >
                            {`+ ${i18n.t("contactModal.buttons.addExtraInfo")}`}
                          </Button>
                        </div>
                      </>
                    )}
                  </FieldArray>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleClose}
                    color="secondary"
                    disabled={isSubmitting}
                    variant="outlined"
                  >
                    {i18n.t("contactModal.buttons.cancel")}
                  </Button>
                  <Can
                    perform={"contact-modal:edit"}
                    role={user.profile}
                    yes={() => (
                      <Button
                        type="submit"
                        color="primary"
                        disabled={isSubmitting}
                        variant="contained"
                        className={classes.btnWrapper}
                      >
                        {contactId
                          ? `${i18n.t("contactModal.buttons.okEdit")}`
                          : `${i18n.t("contactModal.buttons.okAdd")}`}
                        {isSubmitting && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </Button>
                    )}
                  />
                </DialogActions>
              </Form>
            )}
          </Formik>
        ) : (
            <Box
              flex={1}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <CircularProgress />
            </Box>
          )}
      </Dialog>
    </div>
  );
};

export default ContactModal;
