const messages = {
  pt: {
    translations: {
      flow: {
        confirmModal: "Esta ação não pode ser revertida.",
      },
      inactive: {
        title: "Offline por Inatividade",
        message:
          'Você está atualmente offline devido à inatividade. Para restaurar a conexão e ficar online novamente, basta clicar no botão "OK".',
      },
      kanban: {
        column: "Adicionar Coluna",
        info: {
          title: "Detalhes do Usuário",
          name: "Nome",
          number: "Número",
          edited: "Editável",
          platform: "Plataforma",
          close: "Fechar",
        },
        item: {
          ticket: "Ver Ticket",
          info: "Ficha",
          remove: "Remover",
          created: "Criado",
          updated: "Atualizado",
          remove_message: "Você tem certeza?",
        },
        ticket: {
          open: "Atendendo",
          closed: "Resolvido",
          pending: "Aguardando",
          title: "Tickets",
          cancel: "Cancelar",
          add: "Adicionar Tickets",
        },
      },
      general: {
        field: {
          required: "Este campo é obrigatório",
        },
        buttons: {
          save: "salvar",
          add: "adicionar",
          close: "fechar",
          delete: "deletar",
          new: "novo",
          cancel: "Cancelar",
        },
      },
      maintenance: {
        title: "Manutenção em Progresso",
        description:
          "Estamos realizando uma manutenção programada em nosso site. Pedimos desculpas pelo transtorno e esperamos voltar a funcionar em breve.",
      },
      stepOptions: {
        name: "Nome da Etapa",
        message: "Mensagem",
        buttons: {
          delete: "deletar",
        },
      },
      multiSelect: {
        inputLabel: "Opções",
      },
      rotations: {
        title: "Rotações",
        table: {
          number: "Numero",
          groups_count: "Total de Grupos",
          groups: "Grupos",
          limits: "Ticket Máximo",
          actions: "Ações",
          active: "Ativo",
          disabled: "Parado",
          groups_status: "Status",
          wpp_name: "Nome do WhatsApp",
          today: "Tickets - Hoje",
          users: "membros online",
          name: "Nome",
          queue: "Fila",
        },
        buttons: {
          add: "nova rotação",
          disabled: "todas as filas usadas",
          delete: "Você tem certeza?",
        },
        message: {
          delete: "Esta ação não pode revertida",
        },
        modal: {
          title: {
            add: "criar nova Rotação",
            edit: "editar Rotação",
            report: "Relatório de hoje -",
          },
          groups: {
            queue: "fila",
            maxTicket: "limite de ticket",
            queue_label: "Escolha uma fila",
            maxTicket_warn: "ilimitado",
          },
          duplication: "não pode ter filas duplicadas",
          add: "adicionar novo grupo",
          create: "criar",
          edit: "salvar",
        },
      },
      plans: {
        buttons: {
          create: "Novo Plano",
          add: "Adicionar",
          edit: "Salvar",
          cancel: "Cancelar",
        },
        title: "Planos",
        plansModal: {
          created: "Cadastrar um novo plano",
          edited: "Editar plano",

          name: "Nome",
          connections: "Conexões",
          tickets: "Tickets",
          queues: "Filas",
          tags: "Tags",
        },
        confirmModal: {
          delete: "Esta ação não pode ser revertida.",
        },
        grid: {
          name: "Nome",
          companies: "Empresas",
          permissions: "Permissões",
          actions: "Ações",

          cellConnections: "Conexões",
          cellTags: "Tags",
          cellQueues: "Filas",
          cellTickets: "Tickets",
          cellDuration: "Duração (dias)",
        },
        createdAt: "Novo plano criado com sucesso",
        updatedAt: "Plano Atualizado com sucesso",
        deletePlan: "This actions cannot be reversed.",
        deleteTitle: "Are you sure you want to delete ",
      },
      company: {
        title: "Empresas",
        buttons: {
          addCompany: "Nova Empresa",
        },
        companyModal: {
          titleAdd: "Cadastrar uma nova empresa",
          titleEdit: "Editar Empresa",
          name: "Admin Nome",
          email: "Admin Email",
          password: "Admin Senha",
          companyName: "Nome da empresa",
          plan: "Plano",
          required: "Esta propriedade é necessária",
          invalidEmail: "Email é invalido.",
          status: "Status",
          statusActive: "Ativo",
          statusInactive: "Inativo",
        },
        grid: {
          companyName: "Nome da Empresa",
          plan: "Plano",
          members: "Membros",
          createdAt: "Criado em",
          status: "Status",
          active: "Ativo",
          inactive: "Inativo",
          actions: "Ações",
        },
      },
      signup: {
        title: "Cadastre-se",
        toasts: {
          success: "Usuário criado com sucesso! Faça seu login!!!.",
          fail: "Erro ao criar usuário. Verifique os dados informados.",
        },
        form: {
          name: "Nome",
          email: "Email",
          password: "Senha",
        },
        buttons: {
          submit: "Cadastrar",
          login: "Já tem uma conta? Entre!",
        },
      },
      login: {
        title: "Login",
        form: {
          email: "Email",
          password: "Senha",
        },
        buttons: {
          submit: "Entrar",
          register: "Não tem um conta? Cadastre-se!",
        },
      },
      auth: {
        toasts: {
          success: "Login efetuado com sucesso!",
        },
      },
      dashboard: {
        charts: {
          perDay: {
            title: "Tickets hoje: ",
          },
        },
        messages: {
          inAttendance: {
            title: "Em Atendimento",
          },
          waiting: {
            title: "Aguardando",
          },
          closed: {
            title: "Finalizado",
          },
        },
      },
      connections: {
        title: "Conexões",
        toasts: {
          deleted: "Conexão com o WhatsApp excluída com sucesso!",
        },
        confirmationModal: {
          deleteTitle: "Deletar",
          clearTitle: "Limpar Sessão",
          deleteMessage: "Você tem certeza? Essa ação não pode ser revertida.",
          disconnectTitle: "Desconectar",
          disconnectMessage:
            "Tem certeza? Você precisará ler o QR Code ou Login novamente.",
          forceDelete:
            "Tem certeza? Essa ação ira deletar do sistema todos os tickets e mensagens deste Connection. Essa ação não pode ser revertida!!",
        },
        meta: {
          facebook: {
            loginAgain: "Por favor, faça login novamente.",
          },
        },
        buttons: {
          add: "Adicionar WhatsApp",
          fb: "Login com facebook",
          disconnect: "desconectar",
          tryAgain: "Tentar novamente",
          qrcode: "QR CODE",
          newQr: "Novo QR CODE",
          connecting: "Conectando",
          pending: "Por favor, Aguarde",
          restart: "Reiniciar todos os WhatsApp",
        },
        toolTips: {
          disconnected: {
            title: "Falha ao iniciar sessão do WhatsApp",
            content:
              "Certifique-se de que seu celular esteja conectado à internet e tente novamente, ou solicite um novo QR Code",
          },
          qrcode: {
            title: "Esperando leitura do QR Code",
            content:
              "Clique no botão 'QR CODE' e leia o QR Code com o seu celular para iniciar a sessão",
          },
          connected: {
            title: "Conexão estabelecida!",
          },
          timeout: {
            title: "A conexão com o celular foi perdida",
            content:
              "Certifique-se de que seu celular esteja conectado à internet e o WhatsApp esteja aberto, ou clique no botão 'Desconectar' para obter um novo QR Code",
          },
        },
        table: {
          name: "Nome",
          status: "Status",
          lastUpdate: "Última atualização",
          default: "Padrão",
          actions: "Ações",
          session: "Sessão",
          platform: "Plataforma",
        },
      },
      whatsappModal: {
        title: {
          add: "Adicionar WhatsApp",
          edit: "Editar WhatsApp",
        },
        form: {
          name: "Nome",
          default: "Padrão",
          farewellMessage: "Mensagem de despedida",
          isMultidevice: "Multidevice",
          transferTicketMessage: "Mensagem de Transferência do Ticket",
          reopenLastTicket: "Reabrir Ultimo Ticket",
          minutes: "Minutos",
          newticketafter: "Gerar um Novo Ticket após",
          titlenewticketafter: "Ao receber uma mensagem",
          tokenApi: "Token",
          rotate: "Rotação",
          command: {
            name: "Comando",
            tooltip: {
              title: "Ativar por Comando",
              content:
                "Escreva um comando que deseja usar como gatilho para iniciar o fluxo de mensagem, Ex: !filas (Obs: usar um comando desativa a função de mensagem automatica.)",
            },
          },
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "WhatsApp salvo com sucesso.",
      },
      qrCode: {
        message: "Leia o QrCode para iniciar a sessão",
      },
      contacts: {
        title: "Contatos",
        toasts: {
          deleted: "Contato excluído com sucesso!",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle: "Deletar ",
          importTitlte: "Importar contatos",
          deleteMessage:
            "Tem certeza que deseja deletar este contato? Todos os tickets relacionados serão perdidos.",
          importMessage: "Deseja importas todos os contatos do telefone?",
        },
        buttons: {
          import: "Importar Contatos",
          add: "Adicionar Contato",
        },
        table: {
          name: "Nome",
          whatsapp: "Número ou ID",
          email: "Email",
          actions: "Ações",
          platform: "Plataforma",
        },
      },
      contactModal: {
        title: {
          add: "Adicionar contato",
          edit: "Editar contato",
        },
        form: {
          mainInfo: "Dados do contato",
          extraInfo: "Informações adicionais",
          name: "Nome",
          number: "Número do Whatsapp",
          email: "Email",
          extraName: "Nome do campo",
          extraValue: "Valor",
          isEdited: "Editável",
        },
        buttons: {
          addExtraInfo: "Adicionar informação",
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Contato salvo com sucesso.",
      },
      quickAnswersModal: {
        title: {
          add: "Adicionar Resposta Rápida",
          edit: "Editar Resposta Rápida",
        },
        form: {
          shortcut: "Atalho",
          message: "Resposta Rápida",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Resposta Rápida salva com sucesso.",
      },
      queueModal: {
        title: {
          add: "Adicionar fila",
          edit: "Editar fila",
        },
        form: {
          name: "Nome",
          color: "Cor",
          greetingMessage: "Mensagem de saudação",
          transferQueueMessage: "Mensagem de Transferência entre Setor",
          outOfWorkMessage:
            "Personalize a mensagem quando estiver fora do horário de expediente",
          startWorkHour: "Expediente: Abertura",
          endWorkHour: "Expediente: Fechamento",
          monday: "Segunda",
          tuesday: "Terça",
          wednesday: "Quarta",
          thursday: "Quinta",
          friday: "Sexta",
          saturday: "Sábado",
          sunday: "Domingo",
          holiday: "Feriado",
          token: "Token",
          checkHourExpedient: "Checar Horário de Expediente",
          command: {
            name: "Comando",
            tooltip: {
              title: "Ativar por Comando",
              content:
                "Escreva um comando que deseja usar como gatilho para iniciar o fluxo de mensagem, Ex: !serviços",
            },
          },
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
          newStep: "Adicionar Etapa",
        },
      },
      userModal: {
        title: {
          add: "Adicionar usuário",
          edit: "Editar usuário",
        },
        form: {
          name: "Nome",
          email: "Email",
          password: "Senha",
          profile: "Perfil",
          whatsapp: "Conexão Padrão",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Usuário salvo com sucesso.",
      },
      tagModal: {
        title: {
          add: "Nova Tag",
          edit: "Editar Tag",
          kanban: {
            add: "Adicionar nova coluna",
            edit: "Editar Coluna",
          },
        },
        delete: {
          title: "Deletar",
          message: "Você tem certeza? Essa ação não pode ser revertida.",
        },
        form: {
          name: "Nome",
          color: "Cor",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
          delete: "Deletar",
        },
        success: "Tag salvo com sucesso.",
      },
      chat: {
        noTicketMessage: "Selecione um ticket para começar a conversar.",
      },
      ticketsManager: {
        buttons: {
          newTicket: "Novo",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Filas",
      },
      tickets: {
        toasts: {
          deleted: "O ticket que você estava foi deletado.",
        },
        notification: {
          message: "Mensagem de",
        },
        tabs: {
          open: { title: "Inbox" },
          closed: { title: "Resolvidos" },
          search: { title: "Busca" },
        },
        search: {
          placeholder: "Buscar tickets e mensagens",
        },
        buttons: {
          showAll: "Todos",
        },
      },
      transferTicketModal: {
        title: "Transferir Ticket",
        fieldLabel: "Digite para buscar usuários",
        fieldQueueLabel: "Transferir para fila",
        fieldConnectionLabel: "Transferir para conexão",
        fieldQueuePlaceholder: "Selecione uma fila",
        fieldConnectionPlaceholder: "Selecione uma conexão",
        noOptions: "Nenhum usuário encontrado com esse nome",
        buttons: {
          ok: "Transferir",
          cancel: "Cancelar",
        },
      },
      ticketsList: {
        pendingHeader: "Aguardando",
        assignedHeader: "Atendendo",
        noTicketsTitle: "Nada aqui!",
        noTicketsMessage:
          "Nenhum ticket encontrado com esse status ou termo pesquisado",
        connectionTitle: "Conexão que está sendo utilizada atualmente.",
        buttons: {
          accept: "Aceitar",
        },
      },
      newTicketModal: {
        title: "Criar Ticket",
        fieldLabel: "Digite para pesquisar o contato",
        add: "Adicionar",
        buttons: {
          ok: "Salvar",
          cancel: "Cancelar",
        },
      },
      mainDrawer: {
        listItems: {
          dashboard: "Dashboard",
          connections: "Conexões",
          tickets: "Conversas",
          contacts: "Contatos",
          quickAnswers: "Respostas Rápidas",
          queues: "Filas & Chatbot",
          schedules: "Agendamentos",
          tags: "Tags",
          api: "API",
          campaigns: "Campanhas",
          companies: "Empresas",
          configcampanha: "Config. Campanhas",
          relatoriocampanha: "Relatório Envios",
          enviocampanha: "Envio em Massa",
          administration: "Administração",
          users: "Usuários",
          docs: "Documentação",
          tokens: "Tokens",
          settings: "Configurações",
          supervisor: "Supervisor",
          system: "Sistema",
          plans: "Planos",
          rotations: "Rotações",
          kanban: "Kanban",
        },
        appBar: {
          user: {
            profile: "Perfil",
            logout: "Sair",
          },
        },
      },
      notifications: {
        noTickets: "Nenhuma notificação.",
      },
      schedules: {
        title: "Agendamentos",
        confirmationModal: {
          deleteTitle: "Você tem certeza que quer excluir este Agendamento?",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
        table: {
          contact: "Contato",
          body: "Mensagem",
          sendAt: "Data de Agendamento",
          sentAt: "Data de Envio",
          status: "Status",
          actions: "Ações",
        },
        buttons: {
          add: "Novo Agendamento",
        },
        toasts: {
          deleted: "Agendamento excluído com sucesso.",
        },
      },
      scheduleModal: {
        title: {
          add: "Novo Agendamento",
          edit: "Editar Agendamento",
        },
        form: {
          body: "Mensagem",
          contact: "Contato",
          sendAt: "Data de Agendamento",
          sentAt: "Data de Envio",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Agendamento salvo com sucesso.",
      },
      queues: {
        title: "Filas",
        table: {
          name: "Nome",
          color: "Cor",
          greeting: "Mensagem de saudação",
          actions: "Ações",
          steps: "Tem fluxo?",
        },
        buttons: {
          add: "Adicionar fila",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Você tem certeza? Essa ação não pode ser revertida! Os tickets dessa fila continuarão existindo, mas não terão mais nenhuma fila atribuída.",
        },
      },
      queueSelect: {
        inputLabel: "Filas",
      },
      quickAnswers: {
        title: "Respostas Rápidas",
        table: {
          shortcut: "Atalho",
          message: "Resposta Rápida",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar Resposta Rápida",
        },
        toasts: {
          deleted: "Resposta Rápida excluída com sucesso.",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle:
            "Você tem certeza que quer excluir esta Resposta Rápida: ",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
      },
      users: {
        title: "Usuários",
        table: {
          name: "Nome",
          email: "Email",
          profile: "Perfil",
          whatsapp: "Conexão Padrão",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar usuário",
        },
        toasts: {
          deleted: "Usuário excluído com sucesso.",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Todos os dados do usuário serão perdidos. Os tickets abertos deste usuário serão movidos para a fila.",
        },
      },
      tags: {
        title: "Tags",
        confirmationModal: {
          deleteTitle: "Você tem certeza que quer excluir esta Tag?",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
        table: {
          name: "Nome",
          color: "Cor",
          tickets: "Tickets",
          actions: "Ações",
          kanban: {
            title: "Kanban",
            yes: "Sim",
            no: "Não",
          },
        },
        buttons: {
          add: "Nova Tag",
        },
        toasts: {
          deleted: "Tag excluído com sucesso.",
        },
      },
      settings: {
        success: "Configurações salvas com sucesso.",
        title: "Configurações",
        settings: {
          userCreation: {
            name: "Criação de usuário",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },
          timeCreateNewTicket: {
            name: "Mensagem de boas-vindas após",
            note: "Selecione o tempo que será necessário para abrir um novo ticket, caso o cliente entre em contatos novamente",
            options: {
              10: "10 Segundos",
              30: "30 Segundos",
              60: "1 minuto",
              300: "5 minutos",
              1800: "30 minutos",
              3600: "1 hora",
              7200: "2 horas",
              21600: "6 horas",
              43200: "12 horas",
              86400: "24 horas",
              172800: "48 horas",
            },
          },
          call: {
            name: "Aceitar chamadas",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },
          CheckMsgIsGroup: {
            name: "Ignorar Mensagens de Grupos",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },
        },
      },
      messagesList: {
        header: {
          assignedTo: "Atribuído à:",
          buttons: {
            return: "Retornar",
            resolve: "Resolver",
            reopen: "Reabrir",
            accept: "Aceitar",
          },
        },
      },
      messagesInput: {
        placeholderOpen:
          "Digite uma mensagem ou tecle ''/'' para utilizar as respostas rápidas cadastrada",
        placeholderClosed:
          "Reabra ou aceite esse ticket para enviar uma mensagem.",
        signMessage: "Assinar",
      },
      contactDrawer: {
        header: "Dados do contato",
        buttons: {
          edit: "Editar contato",
        },
        extraInfo: "Outras informações",
      },
      ticketOptionsMenu: {
        delete: "Deletar",
        transfer: "Transferir",
        kanban: {
          add: "Adicionar ao Kanban",
          remove: "Remover do Kanban",
          view: "Visualizar no Kanban",
          add_success: "Adicionado ao Kanban",
          remove_success: "Removido do Kanban",
        },
        confirmationModal: {
          title: "Deletar o ticket do contato",
          message:
            "Atenção! Todas as mensagens relacionadas ao ticket serão perdidas.",
        },
        buttons: {
          delete: "Excluir",
          cancel: "Cancelar",
        },
      },
      confirmationModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Cancelar",
        },
      },
      messageOptionsMenu: {
        delete: "Deletar",
        reply: "Responder",
        retry: "Tentar Enviar a mensagem novamente.",
        confirmationModal: {
          title: "Apagar mensagem?",
          message: "Esta ação não pode ser revertida.",
        },
      },
      backendErrors: {
        ERR_TAG_NAME_ALREADY_EXISTS: "Já existe uma tag com esse nome.",
        ERR_NO_OTHER_WHATSAPP: "Deve haver pelo menos um WhatsApp padrão.",
        ERR_NO_DEF_WAPP_FOUND:
          "Nenhum WhatsApp padrão encontrado. Verifique a página de conexões.",
        ERR_WAPP_NOT_INITIALIZED:
          "Esta sessão do WhatsApp não foi inicializada. Verifique a página de conexões.",
        ERR_WAPP_CHECK_CONTACT:
          "Não foi possível verificar o contato do WhatsApp. Verifique a página de conexões",
        ERR_WAPP_INVALID_CONTACT: "Este não é um número de Whatsapp válido.",
        ERR_WAPP_DOWNLOAD_MEDIA:
          "Não foi possível baixar mídia do WhatsApp. Verifique a página de conexões.",
        ERR_INVALID_CREDENTIALS:
          "Erro de autenticação. Por favor, tente novamente.",
        ERR_SENDING_WAPP_MSG:
          "Erro ao enviar mensagem do WhatsApp. Verifique a página de conexões.",
        ERR_DELETE_WAPP_MSG: "Não foi possível excluir a mensagem do WhatsApp.",
        ERR_OTHER_OPEN_TICKET: "Já existe um tíquete aberto para este contato.",
        ERR_SESSION_EXPIRED: "Sessão expirada. Por favor entre.",
        ERR_USER_CREATION_DISABLED:
          "A criação do usuário foi desabilitada pelo administrador.",
        ERR_NO_PERMISSION: "Você não tem permissão para acessar este recurso.",
        ERR_DUPLICATED_CONTACT: "Já existe um contato com este número.",
        ERR_NO_SETTING_FOUND: "Nenhuma configuração encontrada com este ID.",
        ERR_NO_CONTACT_FOUND: "Nenhum contato encontrado com este ID.",
        ERR_NO_TICKET_FOUND: "Nenhum tíquete encontrado com este ID.",
        ERR_NO_USER_FOUND: "Nenhum usuário encontrado com este ID.",
        ERR_NO_WAPP_FOUND: "Nenhum WhatsApp encontrado com este ID.",
        ERR_CREATING_MESSAGE: "Erro ao criar mensagem no banco de dados.",
        ERR_CREATING_TICKET: "Erro ao criar tíquete no banco de dados.",
        ERR_FETCH_WAPP_MSG:
          "Erro ao buscar a mensagem no WhtasApp, talvez ela seja muito antiga.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS:
          "Esta cor já está em uso, escolha outra.",
        ERR_WAPP_GREETING_REQUIRED:
          "A mensagem de saudação é obrigatório quando há mais de uma fila.",
        ERR_CONTAINS_TICKET:
          "Não é possivel deletar esse WhatsApp por conter tickets nele. Deseja força essa ação?",
        ERR_MEDIA_FORMAT_INVALID: "Este formato de media não é valido.",
        ERR_WBOT_NOT_FOUND:
          "Nenhuma sessão foi encontrada. Por favor tente se conectar novamente.",
        ERR_WAP_NOT_CONNECTED:
          "Nenhuma Sessão foi encontrada. Por favor tente se conectar novamente.",
        ERR_NUMBER_IS_NOT_FOUND: "Este número de celular não foi encontrado.",
        ERR_SEND_META_MESSAGE_SERVICE_TEMP_UNAVAILABLE:
          "Serviço temporariamente indisponível",
        ERR_SEND_META_MESSAGE_OUTSIDE_ALLOWED_WINDOW:
          "Essa mensagem foi enviada fora do espaço de tempo permitido. Saiba mais sobre a nova política aqui:  https://developers.facebook.com/docs/messenger-platform/policy-overview",
        ERR_SEND_META_MESSAGE: "Erro inesperado ao enviar a mensagem.",
        ERR_SEND_META_MEDIA: "Erro inesperado ao enviar a mídia.",
      },
    },
  },
};
export { messages };
