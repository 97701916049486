import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import LoggedInLayout from "../layout";
import Dashboard from "../pages/Dashboard/";
import { TicketsPage } from "../pages/Tickets/";
import Login from "../pages/Login/";
import { ConnectionPage } from "../pages/Connections/";
import Settings from "../pages/Settings/";
import Users from "../pages/Users";
import Contacts from "../pages/Contacts/";
import QuickAnswers from "../pages/QuickAnswers/";
import Queues from "../pages/Queues/";
import SendMassMessage from "../pages/SendMassMessage";
import { AuthProvider } from "../context/Auth";
import Route from "./Route";
import Tags from "../pages/Tags";
import Schedules from "../pages/Schedules";
import Company from "../pages/Companies";
import Plans from "../pages/Plans";
import { WhatsAppProvider } from "../context/WhatsApps";
import { QueuesProvider } from "../context/Queues";
import { RotationsPage } from "../pages/Rotations";
import { WarningDialog } from "../components/WarningDialog";
import { InactiveUserDialog } from "../components/InactiveUserDialog";
import { TicketsProvider } from "../context/Tickets";
import { Kanban } from "../pages/Kanban";

const Routes = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Switch>
          <Route exact path="/login" component={Login} />
          <InactiveUserDialog>
            <WarningDialog>
              <TicketsProvider>
                <WhatsAppProvider>
                  <QueuesProvider>
                    <LoggedInLayout>
                      <Route exact path="/" component={Dashboard} isPrivate />
                      <Route
                        exact
                        path="/tickets/:ticketId?"
                        component={TicketsPage}
                        isPrivate
                      />
                      <Route
                        exact
                        path="/connections"
                        component={ConnectionPage}
                        isPrivate
                      />
                      <Route
                        exact
                        path="/rotations"
                        component={RotationsPage}
                        isPrivate
                      />
                      <Route exact path="/tags" component={Tags} isPrivate />
                      <Route
                        exact
                        path="/contacts"
                        component={Contacts}
                        isPrivate
                      />
                      <Route exact path="/users" component={Users} isPrivate />
                      <Route
                        exact
                        path="/schedules"
                        component={Schedules}
                        isPrivate
                      />
                      <Route
                        exact
                        path="/companies"
                        component={Company}
                        isPrivate
                      />
                      <Route
                        exact
                        path="/quickAnswers"
                        component={QuickAnswers}
                        isPrivate
                      />
                      <Route
                        exact
                        path="/BulkMessage"
                        component={SendMassMessage}
                        isPrivate
                      />
                      <Route exact path="/plans" component={Plans} isPrivate />
                      <Route
                        exact
                        path="/Settings"
                        component={Settings}
                        isPrivate
                      />
                      <Route
                        exact
                        path="/Queues"
                        component={Queues}
                        isPrivate
                      />
                      <Route
                        exact
                        path="/kanban"
                        component={Kanban}
                        isPrivate
                      />
                    </LoggedInLayout>
                  </QueuesProvider>
                </WhatsAppProvider>
              </TicketsProvider>
            </WarningDialog>
          </InactiveUserDialog>
        </Switch>
        <ToastContainer autoClose={3000} />
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Routes;
